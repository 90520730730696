
import Header from "@/components/home-page/Header.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LandingPage",
  components: { Header },
  data() {
    return {
      formData: {
        username: '',
        email: '',
        skills: ''
      }
    };
  },
  methods: {
    async submitForm() {
      const webhookUrl = 'https://discord.com/api/webhooks/1292980138102751232/KbEDN5YU9rZtDKaeHLmqCHWHbM71sF1h1k6inviGAI3RuO2siZUSPLCrbNL70Rv80f1z';
      const payload = {
        content: `New Volunteer Application:\n**Name:** ${this.formData.username}\n**Email:** ${this.formData.email}\n**Skills:** ${this.formData.skills}`
      };

      try {
        const response = await fetch(webhookUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (response.ok) {
          alert('Your application has been submitted! Thank you for volunteering!');
          this.resetForm();
        } else {
          throw new Error('Failed to submit form');
        }
      } catch (error) {
        console.error('Error sending data to Discord:', error);
        alert('There was an error submitting your application. Please try again.');
      }
    },
    resetForm() {
      this.formData.username = '';
      this.formData.email = '';
      this.formData.skills = '';
    }
  }
});
